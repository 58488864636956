import { React, useState, useEffect } from 'react';
import logo from '../assets/logo.png';
import backgroundImage from '../assets/topban.jpg';
import { IoLocationSharp } from "react-icons/io5";
import { IoMailSharp } from "react-icons/io5";
import { IoCallSharp } from "react-icons/io5";
import { FloatingWhatsApp } from 'react-floating-whatsapp';
import { IoIosSend } from "react-icons/io";
import Home from '../assets/home2.jpg';
import { FaFacebookF, FaInstagram, FaTwitter, FaPinterestP } from 'react-icons/fa'
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import emailjs from 'emailjs-com';
import { useLocation } from 'react-router-dom';
import Swal from 'sweetalert2'
// import { send, EmailJSResponseStatus } from '@emailjs/react-native';
// import { StyleSheet, Text, View, TextInput, Button } from 'react-native';

// import client from '../assets/client.png';

function contact() {
  return (
    <>
      <Header />
      <ContactBanner />
      <ContactForm />
      {/* <LogoCarousel /> */}
      <Footer />
    </>
  );
}

function Header() {
  const [menuOpen, setMenuOpen] = useState(false);
  const [categoryOpen, setCategoryOpen] = useState(false); // To toggle Category dropdown

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const toggleCategory = () => {
    setCategoryOpen(!categoryOpen); // Toggle category dropdown
  };
  return (
    <header className="bg-white shadow-md py-5">
      <div className="mx-auto flex items-center justify-between py-4 px-5 lg:px-28">
        <div className="w-2/12 max-sm:w-[40vw]">
          {/* Logo Section */}
          <img src={logo} alt="Logo" className="h-12  lg:h-16" />
        </div>

        {/* Desktop Navigation Section */}
        <nav className="w-10/12 px-20 py-8 bg-black hidden md:flex space-x-6 text-white font-semibold">
          <Link to="/" className="hover:text-[#8c2c2c]">Home</Link>
          <Link to="/about" className="hover:text-[#8c2c2c]">About</Link>

          {/* Category with Submenu and Dropdown Icon */}
          <div className="relative group">
            <button className="menu-link hover:text-[#8c2c2c] flex items-center">
              Category

            </button>
            <div className="submenu hidden group-hover:block absolute bg-black text-white shadow-lg">
              <Link to="/modular-kitchen" className="font-sans font-semibold submenu-item text-white hover:bg-black hover:text-white">Modular Kitchen</Link>
              <Link to="/Wardrobe" className="submenu-item text-gray-700 hover:bg-black hover:text-white">Wardrobe</Link>
              <Link to="/pooja-room" className="submenu-item text-gray-700 hover:bg-black hover:text-white">Pooja Room</Link>
              <Link to="/tv-panel" className="submenu-item text-gray-700 hover:bg-black hover:text-white">Tv Panel</Link>
              <Link to="/wall-panel" className="submenu-item text-gray-700 hover:bg-black hover:text-white">Wall Panel</Link>
              <Link to="/wall-shelves" className="submenu-item text-gray-700 hover:bg-black hover:text-white">Wall Shelves</Link>
              <Link to="/show-case" className="submenu-item text-gray-700 hover:bg-black hover:text-white">Show Case</Link>
              <Link to="/false-ceiling" className="submenu-item text-gray-700 hover:bg-black hover:text-white">False Ceiling</Link>
              <Link to="/office-interior" className="submenu-item text-gray-700 hover:bg-black hover:text-white">Office Interior</Link>
            </div>
          </div>
          <Link to="/testimonials" className="hover:text-[#8c2c2c]">Testimonials</Link>
          <Link to="/contact" className="hover:text-[#8c2c2c]">Contact</Link>

          {/* Social Media Links */}
          <div className="flex-grow"></div>
          <div className="flex items-center space-x-3">
            <a href="https://www.facebook.com/elegantkitchencbe" target="_blank" rel="noopener noreferrer">
              <FaFacebookF className="text-white hover:text-[#8c2c2c]" />
            </a>
            <a href="https://www.instagram.com/elegantkitchencbe/" target="_blank" rel="noopener noreferrer">
              <FaInstagram className="text-white hover:text-[#8c2c2c]" />
            </a>
            <a href="https://x.com/Janarth01930131" target="_blank" rel="noopener noreferrer">
              <FaTwitter className="text-white hover:text-[#8c2c2c]" />
            </a>
            <a href="https://www.pinterest.com/elegantkitchenkovai/" target="_blank" rel="noopener noreferrer">
              <FaPinterestP className="text-white hover:text-[#8c2c2c]" />
            </a>
          </div>
        </nav>

        {/* Mobile Menu Button */}
        <button
          onClick={toggleMenu}
          className="p-2 md:hidden text-black lg:text-white">
          ☰
        </button>
      </div>

      {/* Mobile Navigation */}
      {menuOpen && (
        <div className="fixed top-0 left-0 w-full h-full bg-black text-white z-50 flex flex-col items-center justify-center p-4 md:hidden">
          {/* Close Button */}
          <button
            onClick={toggleMenu}
            className="text-white absolute top-5 right-5 text-3xl"
          >
            ✖
          </button>
          <nav className="flex flex-col space-y-6 text-xl text-center">
            <Link to="/" className="hover:text-gray-400" onClick={toggleMenu}>Home</Link>
            <Link to="/about" className="hover:text-gray-400" onClick={toggleMenu}>About</Link>

            {/* Category Dropdown in Mobile View */}
            <div className="relative">
              <button
                className="hover:text-gray-400 flex items-center justify-center"
                onClick={toggleCategory}>
                Category
                <FontAwesomeIcon icon={faChevronDown} className="ml-2" />
              </button>
              {categoryOpen && (
                <div className="mt-2 items-left bg-white">
                  <Link to="/modular-kitchen" className="block px-4 py-2 text-black hover:bg-black hover:text-white" onClick={toggleMenu}>Modular Kitchen</Link>
                  <Link to="/Wardrobe" className="block px-4 py-2 text-black hover:bg-black hover:text-white" onClick={toggleMenu}>Wardrobe</Link>
                  <Link to="/pooja-room" className="block px-4 py-2 text-black hover:bg-black hover:text-white" onClick={toggleMenu}>Pooja Room</Link>
                  <Link to="/tv-panel" className="block px-4 py-2 text-black hover:bg-black hover:text-white" onClick={toggleMenu}>Tv Panel</Link>
                  <Link to="/wall-panel" className="block px-4 py-2 text-black hover:bg-black hover:text-white" onClick={toggleMenu}>Wall Panel</Link>
                  <Link to="/wall-shelves" className="block px-4 py-2 text-black hover:bg-black hover:text-white" onClick={toggleMenu}>Wall Shelves</Link>
                  <Link to="/show-case" className="block px-4 py-2 text-black hover:bg-black hover:text-white" onClick={toggleMenu}>Show Case</Link>
                  <Link to="/false-ceiling" className="block px-4 py-2 text-black hover:bg-black hover:text-white" onClick={toggleMenu}>False Ceiling</Link>
                  <Link to="/office-interior" className="block px-4 py-2 text-black hover:bg-black hover:text-white" onClick={toggleMenu}>Office Interior</Link>
                </div>
              )}
            </div>

            <Link to="/testimonials" className="hover:text-gray-400" onClick={toggleMenu}>Testimonials</Link>
            <Link to="/contact" className="hover:text-gray-400" onClick={toggleMenu}>Contact</Link>
          </nav>
        </div>
      )}
    </header>
  );
}

function ContactBanner() {
  return (
    <section className="relative bg-cover bg-center h-96" style={{ backgroundImage: `url(${backgroundImage})` }} >
      <div className="absolute inset-0 bg-black opacity-50"></div>
      <div className="relative container mx-auto h-full flex flex-col justify-center items-left text-left">
        <h1 className="text-white text-5xl font-bold text-left">CONTACT US</h1>
        <p className="text-white mt-4">
          <a href="/" className="hover:underline">Home</a> &gt; Contact Us
        </p>
      </div>
    </section>
  );
}

function ContactForm() {
 
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    address: '',
    message: '',
  });
  const [result, setResult] = useState("");

  const sendEmail = async (event) => {
    event.preventDefault();
    setResult("Sending....");
    const formDataObject = new FormData(event.target);

    formDataObject.append("access_key", "daa86d46-303d-4b43-b284-e967559000ea");

    const response = await fetch("https://api.web3forms.com/submit", {
      method: "POST",
      body: formDataObject
    });

    const data = await response.json();

    if (data.success) {
      Swal.fire({
        // title: "Good job!",
        text: "Form Submitted Sucessfully",
        icon: "success"
      })
      .then(() => {
        // Reload the page after clicking "OK"
        window.location.reload();
      });
    } else {
      console.log("Error", data);
      setResult(data.message);
    }
   
  };
  return (
    <section className="py-16 ">
      <div className="relative container mx-auto flex flex-wrap justify-left  bg-center" style={{ backgroundImage: `url(${Home})` }}>
        {/* Form Section */}
        <div className="w-full lg:w-6/12 mb-8 lg:mb-0 relative z-10 p-8 bg-white bg-opacity-90 ">
          <h2 className="text-4xl font-sans font-bold mb-6 text-center">Contact Us</h2>
          <p className="font-sans font-normal text-lg mb-6 text-center">
            Contact us today for classy Design Expertise. Best Quality Solutions. After-sales support. Services crafted & customised just for your dream space.
          </p>
          <form className='space-y-6' id="contact-form" onSubmit={sendEmail}>
            <div className="flex space-x-4">
              <input
                type="text"
                name="name"
                placeholder="First name"
                value={formData.name}
                onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                className="w-1/2 p-4 border rounded-md"
              />
              <input
                type="email"
                name="email"
                placeholder="Your Email"
                value={formData.email}
                onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                className="w-1/2 p-4 border rounded-md"
              />
            </div>
            <div className="flex space-x-4">
              <input
                type="text"
                name="phone"
                placeholder="Phone"
                value={formData.phone}
                onChange={(e) => setFormData({ ...formData, phone: e.target.value })}
                className="w-1/2 p-4 border rounded-md"
              />
              <input
                type="text"
                name="address"
                placeholder="Address"
                value={formData.address}
                onChange={(e) => setFormData({ ...formData, address: e.target.value })}
                className="w-1/2 p-4 border rounded-md"
              />
            </div>
            <textarea
              name="message"
              placeholder="Message"
              value={formData.message}
              onChange={(e) => setFormData({ ...formData, message: e.target.value })}
              className="w-full p-4 border rounded-md"
            ></textarea>
            <button
              type="submit"
              className="w-full lg:w-auto bg-black text-white py-4 px-8 rounded-md hover:bg-gray-800"
            >
              Send Message
            </button>
          </form>
        </div>

        {/* Image Section */}
        <div className=" lg:block lg:w-6/12 mb-0 lg:mb-0">
          <img src={Home} alt="" className="w-full h-full object-cover" />
        </div>
      </div>
      <div className="w-full lg:w-12/12  text-black py-12 px-20 max-sm:px-0">
        <div className="text-center mb-12">
          <h2 className="text-4xl font-sans font-bold mb-6">Get in Touch</h2>
          <p className="font-sans font-normal text-lg mb-6 text-center">
            It is a long established fact that a reader will be distracted by the readable content.
          </p>
        </div>

        <div className="flex flex-col lg:flex-row lg:justify-center lg:space-x-8 space-y-8 lg:space-y-0 px-4 lg:px-0">
          {/* Address Box */}
          <div className="bg-black text-white p-6 rounded-lg shadow-lg transform transition-all hover:scale-105 duration-300 w-full lg:w-1/3">
            <div className="flex items-left">
              <IoLocationSharp className="text-2xl text-white mr-4" />
              <div>
                <p className="font-bold font-sans text-2xl ">Address</p><br />
                <p>
                  250/385, Padma Complex, <br />
                  7th Street, Crosscut Road, <br />
                  Coimbatore – 641 012.
                </p>
              </div>
            </div>
          </div>

          {/* Email Box */}
          <div className="bg-black text-white p-6 rounded-lg shadow-lg transform transition-all hover:scale-105 duration-300 w-full lg:w-1/3">
            <div className="flex items-left">
              <IoMailSharp className="text-2xl text-white mr-4" />
              <div>
                <p className="font-bold text-2xl font-sans">Email Address</p><br />
                <p>elegantkitchen.cbe@gmail.com</p>
              </div>
            </div>
          </div>

          {/* Phone Box */}
          <div className="bg-black text-white p-6 rounded-lg shadow-lg transform transition-all hover:scale-105 duration-300 w-full lg:w-1/3">
            <div className="flex items-left">
              <IoCallSharp className="text-2xl text-white mr-4" />
              <div>
                <p className="font-bold text-2xl font-sans">Phone Number</p><br />
                <p>+91 99407 12867<br />+91 98431 10677</p>
              </div>
            </div>
          </div>
        </div>
      </div>



      {/* // <!-- Google Map Section --> */}
      <div className="w-full h-[70vh] mt-16 px-10 max-sm:px-4">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3916.271125671885!2d76.96359707402051!3d11.018273054688333!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ba85855c7960845%3A0xd5dc43cc377ceed!2sElegant%20Kitchen!5e0!3m2!1sen!2sin!4v1725002648364!5m2!1sen!2sin"
          width="100%"
          height="100%"
          allowFullScreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
          className="border-0"
        ></iframe>
      </div>

    </section >
  );
}

// function LogoCarousel() {
//   const logos = [
//     { id: 1, src: client },
//     { id: 2, src: 'logo2.png' },
//     { id: 3, src: 'logo3.png' },
//     { id: 4, src: 'logo4.png' },
//   ];

//   return (
//     <section className="bg-black py-16">
//       <div className="container mx-auto flex justify-around">
//         {logos.map(logo => (
//           <img key={logo.id} src={logo.src} alt={`Logo ${logo.id}`} className="h-12 sm:h-16" />
//         ))}
//       </div>
//     </section>
//   );
// }
function Footer() {
  return (
    <div>
      {/* Subscribe Newsletter Section */}
      {/* <section className="bg-black py-16 text-center"> */}
      {/* <div className="max-w-6xl mx-auto px-4">
          <div className="flex flex-col md:flex-row items-center space-y-6 md:space-y-0 md:space-x-6">
            <div className="w-full md:w-1/2 text-center md:text-left">
              <h2 className="text-white text-3xl md:text-4xl font-bold mb-6 md:mb-0 ">

                SUBSCRIBE FOR<br className="hidden md:block" />UPDATES
              </h2>
            </div>
            <div className="w-full md:w-1/2 flex justify-center md:justify-start items-center">
              <div className="flex flex-col md:flex-row w-full md:w-auto space-y-4 md:space-y-0 md:space-x-4">
                <input
                  type="email"
                  placeholder="Enter Your Email"
                  className="w-full md:w-64 p-3 border-none outline-none rounded-md"
                />
                <button className="bg-black text-white py-3 px-6 rounded-md font-semibold">
                  Subscribe Now
                </button>
              </div>
            </div>
          </div>
        </div> */}
      {/* </section> */}

      {/* Footer Section */}
      <footer className=" bg-black text-white">
        <div className=" pt-10 mx-10 grid grid-cols-1 lg:grid-cols-4 md:grid-cols-3 gap-8 ">
          {/* Column 1: Logo and Description */}
          <div>
            <img src={logo} alt="Logo" className="h-20 lg:mr-24 md:mr-4 " />
            <p className='pt-5 font-sans font-medium'>We welcome you to our wonderful world of Interior and Furniture.</p>
          </div>

          {/* Column 2: Pages Links */}
          <div>
            <h4 className="font-sans text-lg font-semibold mb-4">Useful Links</h4>
            <ul>
              <li><a href="#" className="font-sans font-normal hover:underline pb-10 ">About Us</a></li><br></br>
              <li><a href="#" className="font-sans font-normal pb-2 hover:underline">Testimonial</a></li><br></br>
              <li><a href="/contact" className="font-sans font-normal  pb-2 hover:underline">Contact</a></li>
            </ul>
          </div>

          {/* Column 3: Office Information */}
          <div>
            <h4 className="text-lg font-sans font-semibold mb-4">Contact Us</h4>
            <ul>
              <li className="flex items-start mb-4 mt-5 ">
                <IoLocationSharp className="text-4xl mr-4 circle" />
                <span className='pl-2'>250/385, Padma Complex, 7th Street, Crosscut Road, Coimbatore - 641 012.</span>
              </li>
              <li className="flex items-center mb-4">
                <IoCallSharp className="text-2xl mr-4" />
                <span className='pl-2'>+91 99407 12867</span>
              </li>
              <li className="flex items-center">
                <IoMailSharp className="text-2xl mr-4" />
                <span className='pl-2'>elegantkitchen.cbe@gmail.com</span>
              </li>
            </ul>
          </div>

          {/* Column 4: Newsletter */}
          <div>
            <h4 className="text-lg font-sans font-semibold mb-4">Newsletter</h4>
            <p>Contact us today for classy Design Expertise. Best Quality Solutions.</p>
            <div className="mt-4 flex">
              <input
                type="email"
                placeholder="Email here"
                className="p-3 w-full rounded-l-md text-black border border-black outline-none"
              />
              <button className="bg-black text-white p-3 rounded-r-md">
                <IoIosSend />
              </button>
            </div>
          </div>
        </div >
        <div className="bg-black text-white py-4 mt-14 mx-auto text-center border-t-2 border-gray-900">
          <p>&copy; 2024 Elegant Kitchen. All rights reserved.</p>
        </div>
      </footer>
      <FloatingWhatsApp />
    </div>
  );
}


export default contact;


